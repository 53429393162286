
// Libraries
import * as React from 'react'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import DuoSection from '../../../components/duoSection'
import Button from '../../../components/button'

class RegisterOrganisationSuccessPage extends React.Component {
	render() {
		return <Layout className="page--success page--success-organisation nav-blue-half no-footer">
			<Seo title="Register Organisation Success" />
			<DuoSection success>
				<div>
					<div className="content-wrap">
						<h1>Success!</h1>
						<p>
							Thank you for registering your organisation. Your employees can now register to apply for ballots.
						</p>
						<Button to="/register" hollow>Register as Carer</Button>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}
}

export default RegisterOrganisationSuccessPage
